var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',[_c('img',{staticClass:"artist-header-img",attrs:{"src":_vm.detailContent.image1,"alt":""}})]),_c('div',{staticClass:"main-wrap"},[_c('div',{staticClass:"artist-title font-medium text-over-1"},[_vm._v(" "+_vm._s(_vm.$trans( ("artist-" + (_vm.detailContent.id) + ".detailTitle"), _vm.detailContent.detailTitle ))+" ")]),_c('div',{staticClass:"artist-author font-medium"},[_vm._v(" 「"+_vm._s(_vm.$trans(("artist-" + (_vm.detailContent.id) + ".name"), _vm.detailContent.name))+"」 ")]),_c('div',{staticClass:"paragraph",domProps:{"innerHTML":_vm._s(
        _vm.detailContent.content != '<p><br></p>'
          ? _vm.$trans(
              ("artist-" + (_vm.detailContent.id) + ".content"),
              _vm.detailContent.content
            )
          : _vm.detailContent.content
      )}}),_c('div',{staticClass:"next-wrap flex-bet-cen"},[_c('i',{staticClass:"iconfont icon-arrowLeft-fill",attrs:{"title":_vm.$trans('上一篇', '上一篇')},on:{"click":function($event){return _vm.getArtistDetailNext(-1)}}}),_c('i',{staticClass:"iconfont icon-arrowRight",attrs:{"title":_vm.$trans('下一篇', '下一篇')},on:{"click":function($event){return _vm.getArtistDetailNext(+1)}}})])]),_c('Footer'),_c('Float',{attrs:{"artistId":_vm.id}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }