<template>
  <div>
    <div>
      <img class="artist-header-img" :src="detailContent.image1" alt="" />
    </div>
    <div class="main-wrap">
      <div class="artist-title font-medium text-over-1">
        {{
          $trans(
            `artist-${detailContent.id}.detailTitle`,
            detailContent.detailTitle
          )
        }}
      </div>
      <div class="artist-author font-medium">
        「{{ $trans(`artist-${detailContent.id}.name`, detailContent.name) }}」
      </div>
      <div
        v-html="
          detailContent.content != '<p><br></p>'
            ? $trans(
                `artist-${detailContent.id}.content`,
                detailContent.content
              )
            : detailContent.content
        "
        class="paragraph"
      ></div>
      <!-- <div v-for="(item, index) in detailContent.content" :key="index">
        <img
          class="paragraph"
          :src="detailContent.image2"
          alt=""
          v-if="index == 3"
        />
        <p class="paragraph">{{ item }}</p>
      </div> -->
      <!-- <div class="share-wrap flex-cen">
                <i class="iconfont icon-fenxiang"></i>
                <span clss="font-medium">分享</span>
            </div> -->
      <div class="next-wrap flex-bet-cen">
        <i
          @click="getArtistDetailNext(-1)"
          class="iconfont icon-arrowLeft-fill"
          :title="$trans('上一篇', '上一篇')"
        ></i>
        <i
          @click="getArtistDetailNext(+1)"
          class="iconfont icon-arrowRight"
          :title="$trans('下一篇', '下一篇')"
        ></i>
      </div>
    </div>
    <Footer />
    <Float :artistId="id" />
  </div>
</template>
<script>
import { loadLocaleMessages } from '@/i18n/i18n'
import Footer from '@/components/layout/Footer.vue'
import { getArtistDetail, allArtistId } from '@/util/js/api.js'
import Float from '@/components/float'
export default {
  name: '',
  data() {
    return {
      detailContent: {},
      artistIdList: [],
      id: 0,
    }
  },
  components: {
    Footer,
    Float,
  },
  metaInfo() {
    return {
      title:
        this.detailContent && this.detailContent.title
          ? `${this.detailContent.title}-${this.detailContent.name}-上殊房`
          : '上殊房',
      meta: [
        {
          vmid: 'description',
          name: 'description',
          content:
            this.detailContent &&
            this.detailContent.content &&
            this.detailContent.content.length > 0
              ? this.detailContent.content[0]
              : '',
        },
      ],
    }
  },
  mounted() {
    this.id = this.$route.params.id
    this.getArtistDetail()
    this.allArtistId()
  },
  methods: {
    // 获取艺术家详情
    getArtistDetail() {
      getArtistDetail(this.id).then((res) => {
        this.detailContent = JSON.parse(JSON.stringify(res.data))
        // let arr =
        //   this.detailContent.content.indexOf('<sign>') > -1
        //     ? this.detailContent.content.split('<sign>')
        //     : [this.detailContent.content]
        // let images = this.detailContent.profileImg2 ? this.detailContent.profileImg2.split(",") : [this.detailContent.profileImg2];
        // this.$set(this.detailContent, 'title', arr[0])
        this.$set(
          this.detailContent,
          'image1',
          res.data.profileImg2.split(',')[0]
        )
        // this.$set(
        //   this.detailContent,
        //   'image2',
        //   res.data.profileImg2.split(',')[1]
        // )
        // this.detailContent.content = arr.slice(1, arr.length)
        if (res.data.transferList) {
          loadLocaleMessages(res.data.transferList, `artist-${res.data.id}`)
        }
      })
    },
    // 获取所有艺术家id
    allArtistId() {
      allArtistId().then((res) => {
        if (res.code == 200) {
          this.artistIdList = JSON.parse(JSON.stringify(res.data))
        }
      })
    },
    // 获取下一篇
    getArtistDetailNext(next) {
      let index = this.artistIdList.indexOf(this.id) + next
      // console.log(index)
      if (index < 0) {
        this.$message.warning(
          this.$trans('这已经是第一篇了', '这已经是第一篇了')
        )
        return
      } else {
        if (index >= this.artistIdList.length) {
          this.$message.warning(
            this.$trans('这已经是最后一篇了', '这已经是最后一篇了')
          )
          return
        }
      }
      this.id = this.artistIdList[index]
      getArtistDetail(this.id).then((res) => {
        if (res.data) {
          this.detailContent = JSON.parse(JSON.stringify(res.data))
          // console.log('this.detailContent:', this.detailContent)
          if (res.data.transferList) {
            loadLocaleMessages(res.data.transferList, `artist-${res.data.id}`)
          }
          // let arr =
          //   this.detailContent.content.indexOf('<sign>') > -1
          //     ? this.detailContent.content.split('<sign>')
          //     : [this.detailContent.content]
          let images = this.detailContent.profileImg2
            ? this.detailContent.profileImg2.split(',')
            : [this.detailContent.profileImg2]
          // this.$set(this.detailContent, 'title', arr[0])
          this.$set(this.detailContent, 'image1', images[0])
          // this.$set(this.detailContent, 'image2', images[1])
          // this.detailContent.content = arr.slice(1, arr.length - 1)
        } else {
          if (next > 0) {
            this.$message.warning(
              this.$trans('这已经是最后一篇了', '这已经是最后一篇了')
            )
            this.$route.params.id = this.$route.params.id - 1
          } else {
            this.$message.warning(
              this.$trans('这已经是第一篇了', '这已经是第一篇了')
            )
            this.$route.params.id = this.$route.params.id + 1
          }
        }
      })
    },
  },
}
</script>
<style scoped>
.artist-header-img {
  width: 100%;
  height: auto;
}
.main-wrap {
  max-width: 800px;
  margin: 60px auto;
  padding-bottom: 100px;
}
.artist-title {
  font-size: 24px;
  text-align: center;
  line-height: 2;
}
.artist-author {
  font-size: 18px;
  text-align: center;
  line-height: 2;
  margin-bottom: 30px;
}
.paragraph {
  line-height: 2;
  font-size: 14px;
  margin-bottom: 40px;
  letter-spacing: 2px;
}
.share-wrap {
  font-size: 20px;
  font-size: 16px;
  margin-top: 100px;
  margin-bottom: 100px;
  cursor: pointer;
}
.share-wrap .icon-fenxiang {
  font-size: 32px;
  margin-right: 20px;
}
.icon-arrowRight,
.icon-arrowLeft-fill {
  font-size: 32px;
  cursor: pointer;
}
</style>
