<template>
  <div class="float-positon">
    <a
      @click="gotoDetail"
      class="float-display"
      @mouseenter="onMouseIn()"
      @mouseleave="leaver()"
    >
      <img :src="imagePath" />
      <span>{{ $trans('查看作品', '查看作品') }}</span>
    </a>
  </div>
</template>
<script>
export default {
  name: '',
  data() {
    return {
      imagePath: require('@/assets/img/float1.png'),
    }
  },
  props: {
    artistId: {
      typeof: Number,
      default: '',
    },
  },
  methods: {
    onMouseIn() {
      this.imagePath = require('@/assets/img/float2.png')
    },
    leaver() {
      this.imagePath = require('@/assets/img/float1.png')
    },
    gotoDetail() {
      console.log('goto')
      this.$router.history.push({
        path: '/gallery',
        query: {
          id: this.artistId,
        },
      })
    },
  },
  mounted() {},
}
</script>

<style scoped>
.float-positon {
  position: fixed;
  right: 7%;
  bottom: 200px;
  z-index: 999;
}

.float-display {
  justify-content: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
}
.float-display img {
  width: 35px;
  margin-bottom: 10px;
}
.float-display span {
  /* writing-mode: vertical-rl; */
  font-size: 12px;
}
</style>
